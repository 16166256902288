.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    animation: slide-down 0.5s ease;
  }

  .form {
    width: 35rem;
    padding: 1.5rem;
    animation: slide-up 0.5s ease;
    background-color: #fff;
    h2 {
      color: var(--color-danger);
      text-align: center;
    }
    form {
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #ccc;
        border-bottom: 3px solid #ccc;
        border-radius: 3px;
        outline: none;

        &:focus {
          outline: none;
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
          border-bottom: 3px solid #55c57a;
        }

        &:focus:invalid {
          border-bottom: 3px solid #ff7730;
        }
      }
      .links {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
      }

      p {
        text-align: center;
        margin: 1rem;
      }
    }
    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
  }
  
  .FreeTrial {
    display: flex;
    justify-content: center;
    width: 100%; // Adjust width as needed
  }

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 700px) {
  .img {
    display: none;
  }
}
